/*
format
staticPath => for getstaticpath into [route]/[[...query]] to generate static page
noPath => not include into staticpath
animateHeader => header animate transparent to white
pattern => allow pass through else 404
ignore => ignore for checking to allow pass, if not match 404, not include for staticpath
*/
const routers = [
  {
    staticPath: [
      { params: { route: '' }, locale: 'en' },
      { params: { route: 'fr' }, locale: 'fr' },
      { params: { route: 'es' }, locale: 'es' },
    ],
    animateHeader: false,
    name: 'home',
    pattern: '/:lang',
    ignore: true,
  },
  {
    staticPath: [
      { params: { route: 'services', query: [] }, locale: 'en' },
      { params: { route: 'services', query: [] }, locale: 'fr' },
      { params: { route: 'servicios', query: [] }, locale: 'es' },
    ],
    animateHeader: false,
    componentPage: 'services',
    name: 'services',
    pattern: '/:route(\\?category=:category&subCategory=:subCategory(&partnerRef=:partnerRef))',
    newP: true,
  },
  {
    staticPath: [
      { params: { route: 'press-media', query: [] }, locale: 'en' },
      { params: { route: 'presse-media', query: [] }, locale: 'fr' },
      { params: { route: 'medios-prensa', query: [] }, locale: 'es' },
    ],
    animateHeader: false,
    componentPage: 'press',
    name: 'press',
    pattern: '/:route',
  },
  {
    staticPath: [
      { params: { route: 'sponsorship', query: [] }, locale: 'en' },
      { params: { route: 'parrainage', query: [] }, locale: 'fr' },
      { params: { route: 'patrocinio', query: [] }, locale: 'es' },
    ],
    animateHeader: false,
    componentPage: 'sponsorship',
    name: 'sponsorship',
    pattern: '/:route',
  },
  {
    staticPath: [
      { params: { route: 'contact', query: [] }, locale: 'en' },
      { params: { route: 'nous-contacter', query: [] }, locale: 'fr' },
      { params: { route: 'contactos', query: [] }, locale: 'es' },
    ],
    animateHeader: false,
    componentPage: 'contact',
    name: 'contact',
    pattern: '/:route',
  },
  {
    staticPath: [
      { params: { route: 'be-rider', query: [] }, locale: 'en' },
      { params: { route: 'louer-un-espace', query: [] }, locale: 'fr' },
      { params: { route: 'alquilar-un-espacio', query: [] }, locale: 'es' },
    ],
    animateHeader: true,
    componentPage: 'howto-ride',
    name: 'howto-ride',
    pattern: '/:route',
  },
  {
    staticPath: [
      { params: { route: 'be-host', query: [] }, locale: 'en' },
      { params: { route: 'devenir-hote', query: [] }, locale: 'fr' },
      { params: { route: 'convertirse-en-anfitrion', query: [] }, locale: 'es' },
    ],
    animateHeader: true,
    componentPage: 'become-host',
    name: 'become-host',
    pattern: '/:route',
  },
  {
    staticPath: [
      { params: { route: 'insurance', query: [] }, locale: 'en' },
      { params: { route: 'assurance', query: [] }, locale: 'fr' },
      { params: { route: 'seguro', query: [] }, locale: 'es' },
    ],
    animateHeader: true,
    componentPage: 'insurance',
    name: 'insurance',
    pattern: '/:route',
  },
  {
    staticPath: [
      { params: { route: 'corporate-membership', query: [] }, locale: 'en' },
      { params: { route: 'accompagnement-workstars', query: [] }, locale: 'fr' },
      { params: { route: 'membresia-corporativa', query: [] }, locale: 'es' },
    ],
    animateHeader: false,
    componentPage: 'workstars',
    name: 'corporate-membership',
    pattern: '/:route',
  },
  {
    staticPath: [
      { params: { route: 'terms', query: [] }, locale: 'en' },
      { params: { route: 'conditions', query: [] }, locale: 'fr' },
      { params: { route: 'condiciones', query: [] }, locale: 'es' },
    ],
    animateHeader: false,
    componentPage: 'terms',
    name: 'terms',
    pattern: '/:route',
  },
  {
    staticPath: [
      { params: { route: 'rooms', query: [] }, locale: 'en' },
      { params: { route: 'salles', query: [] }, locale: 'fr' },
      { params: { route: 'salas', query: [] }, locale: 'es' },
    ],
    animateHeader: false,
    componentPage: 'space-public',
    name: 'spacepublic',
    pattern: '/:route/:slug',
  },
  {
    staticPath: [
      { params: { route: 'space_add' }, locale: 'en', externalUrl: `${process.env.NEXT_PUBLIC_URL_V3}/space/add?v4` },
      { params: { route: 'espace_ajout' }, locale: 'fr', externalUrl: `${process.env.NEXT_PUBLIC_URL_V3}/fr/espace/ajout?v4` },
      { params: { route: 'space_add' }, locale: 'es', externalUrl: `${process.env.NEXT_PUBLIC_URL_V3}/es/space/add?v4` },
    ],
    ignore: true,
    name: 'add_space_v3',
  },
  {
    staticPath: [
      { params: { route: 'user_account' }, locale: 'en', externalUrl: `${process.env.NEXT_PUBLIC_URL_V3}/user/account` },
      { params: { route: 'user_account' }, locale: 'fr', externalUrl: `${process.env.NEXT_PUBLIC_URL_V3}/fr/membre/compte` },
      { params: { route: 'user_account' }, locale: 'es', externalUrl: `${process.env.NEXT_PUBLIC_URL_V3}/es/miembro/cuenta` },
    ],
    ignore: true,
    name: 'user_account_v3',
  },
  {
    staticPath: [
      { params: { route: 'user_profile' }, locale: 'en', externalUrl: `${process.env.NEXT_PUBLIC_URL_V3}/user/profile` },
      { params: { route: 'user_profile' }, locale: 'fr', externalUrl: `${process.env.NEXT_PUBLIC_URL_V3}/fr/membre/profil` },
      { params: { route: 'user_profile' }, locale: 'es', externalUrl: `${process.env.NEXT_PUBLIC_URL_V3}/es/miembro/perfil` },
    ],
    ignore: true,
    name: 'user_profile_v3',
  },
  {
    staticPath: [
      { params: { route: 'dash_host_spaces' }, locale: 'en', externalUrl: `${process.env.NEXT_PUBLIC_URL_V3}/dashboard/host/spaces` },
      { params: { route: 'dash_host_spaces' }, locale: 'fr', externalUrl: `${process.env.NEXT_PUBLIC_URL_V3}/fr/dashboard/hote/espaces` },
      { params: { route: 'dash_host_spaces' }, locale: 'es', externalUrl: `${process.env.NEXT_PUBLIC_URL_V3}/es/dashboard/host/spaces` },
    ],
    ignore: true,
    name: 'dash_host_spaces',
  },
  {
    staticPath: [
      { params: { route: 'dash_host_book' }, locale: 'en', externalUrl: `${process.env.NEXT_PUBLIC_URL_V4}/dashboard/host` },
      { params: { route: 'dash_host_book' }, locale: 'fr', externalUrl: `${process.env.NEXT_PUBLIC_URL_V4}/dashboard/host` },
      { params: { route: 'dash_host_book' }, locale: 'es', externalUrl: `${process.env.NEXT_PUBLIC_URL_V4}/dashboard/host` },
    ],
    ignore: true,
    name: 'dash_host_book',
  },
  {
    staticPath: [
      { params: { route: 'dash_rider_book', query: [] }, locale: 'en', externalUrl: `${process.env.NEXT_PUBLIC_URL_V4}/dashboard/rider` },
      { params: { route: 'dash_rider_book', query: [] }, locale: 'fr', externalUrl: `${process.env.NEXT_PUBLIC_URL_V4}/dashboard/rider` },
      { params: { route: 'dash_rider_book', query: [] }, locale: 'es', externalUrl: `${process.env.NEXT_PUBLIC_URL_V4}/dashboard/rider` },
    ],
    ignore: true,
    name: 'dash_rider_book',
  },
  {
    staticPath: [
      { params: { route: 'selection', query: [] }, locale: 'en' },
      { params: { route: 'selection', query: [] }, locale: 'fr' },
      { params: { route: 'selection', query: [] }, locale: 'es' },
    ],
    name: 'bookmark',
    pattern: '/:route/:ref',
    ignore: true,
  },
  {
    staticPath: [
      { params: { route: 'corpo', query: [] }, locale: 'en' },
      { params: { route: 'corpo', query: [] }, locale: 'fr' },
      { params: { route: 'corpo', query: [] }, locale: 'es' },
    ],
    name: 'corpo',
    pattern: '/:route/:page',
    ignore: true,
  },

  {
    staticPath: [
      { params: { route: 'forgotpassword' }, locale: 'en', externalUrl: `${process.env.NEXT_PUBLIC_URL_V3}/forgot-password` },
      { params: { route: 'forgotpassword' }, locale: 'fr', externalUrl: `${process.env.NEXT_PUBLIC_URL_V3}/fr/mot-de-passe-oublie` },
      { params: { route: 'forgotpassword' }, locale: 'es', externalUrl: `${process.env.NEXT_PUBLIC_URL_V3}/es/contrasena-olvidada` },
    ],
    ignore: true,
    name: 'forgotpassword',
  },
  {
    staticPath: [
      { params: { route: 'createaccount' }, locale: 'en', externalUrl: `${process.env.NEXT_PUBLIC_URL_V3}/signup` },
      { params: { route: 'createaccount' }, locale: 'fr', externalUrl: `${process.env.NEXT_PUBLIC_URL_V3}/fr/inscription` },
      { params: { route: 'createaccount' }, locale: 'es', externalUrl: `${process.env.NEXT_PUBLIC_URL_V3}/es/registrar` },
    ],
    ignore: true,
    name: 'createaccount',
  },
];

// by cities slug and district
const cities = [
  {
    slug: 'paris',
    title: 'Paris',
    count: 20,
  },
  {
    slug: 'lyon',
    title: 'Lyon',
    count: 9,
  },
  {
    slug: 'bordeaux',
    title: 'Bordeaux',
    count: 8,
    countItems: [
      { slug: 'nansouty-genes', title: 'Nansouty – St Genès' },
      { slug: 'centre-ville', title: 'Centre Ville' },
      { slug: 'st-augustin', title: 'Saint Augustin' },
      { slug: 'sud', title: 'Sud' },
      { slug: 'cauderan', title: 'Caudéran' },
      { slug: 'chartrons', title: 'Chartrons' },
      { slug: 'maritime', title: 'Maritime' },
      { slug: 'bastide', title: 'Bastide' },
    ],
  },
  {
    slug: 'marseille',
    title: 'Marseille',
    count: 0,
  },
  {
    slug: 'toulouse',
    title: 'Toulouse',
    count: 0,
  },
  {
    slug: 'nantes',
    title: 'Nantes',
    count: 0,
  },
  {
    slug: 'strasbourg',
    title: 'Strasbourg',
    count: 0,
  },
  {
    slug: 'lille',
    title: 'Lille',
    count: 10,
    countItems: [
      { slug: 'vieux-lille', title: 'Vieux Lille' },
      { slug: 'sud', title: 'Sud' },
      { slug: 'lille-wazemmes', title: 'Lille Wazemmes' },
      { slug: 'vauban-esquermes', title: 'Vauban Esquermes' },
      { slug: 'saint-maurice-pellevoisin', title: 'Saint-Maurice Pellevoisin' },
      { slug: 'moulins', title: 'Moulins' },
      { slug: 'fives', title: 'Fives' },
      { slug: 'faubourg-bethune', title: 'Faubourg de Béthune' },
      { slug: 'centre', title: 'Centre' },
      { slug: 'bois-blanc', title: 'Bois Blanc' },
    ],
  }];

cities.forEach(({ slug, count, countItems }) => {
  routers.push({
    staticPath: [
      { params: { route: 'france', query: [slug], city: slug }, locale: 'en' },
      { params: { route: 'france', query: [slug], city: slug }, locale: 'fr' },
      { params: { route: 'france', query: [slug], city: slug }, locale: 'es' },
    ],
    animateHeader: true,
    componentPage: 'guide-city',
    name: `guides-france-${slug}`,
    pattern: '/:route/:city',
  });
  for (let i = 1; i <= count; i += 1) {
    const icount = countItems?.[i - 1]?.slug || `${slug}-${i}e`;
    const iqueryCount = `${icount}`;
    routers.push({
      staticPath: [
        {
          params: {
            route: 'france', query: [slug, iqueryCount], city: slug, slug: iqueryCount,
          },
          locale: 'en',
        },
        {
          params: {
            route: 'france', query: [slug, iqueryCount], city: slug, slug: iqueryCount,
          },
          locale: 'fr',
        },
        {
          params: {
            route: 'france', query: [slug, iqueryCount], city: slug, slug: iqueryCount,
          },
          locale: 'es',
        },
      ],
      animateHeader: true,
      componentPage: 'guide-city',
      name: `guides-france-district-${slug}`,
      pattern: '/:route/:city/:slug',
    });
  }
});

const guidestypebycity = [
  {
    fr: 'salle-reunion',
    en: 'meeting-room',
    es: 'sala-reuniones',
  },
  {
    fr: 'showroom',
    en: 'rental-showroom',
    es: 'alquiler-sala-exposiciones',
  },
  {
    fr: 'salle-conference',
    en: 'conference-room',
    es: 'sala-conferencias',
  },
  {
    fr: 'espace-atypique',
    en: 'atypical-space',
    es: 'espacio-atipico',
  },
  {
    fr: 'salle-formation',
    en: 'training-room',
    es: 'sala-formacion',
  },
  {
    fr: 'lieux-tournage',
    en: 'shooting-locations',
    es: 'lugares-rodaje',
  },
];

guidestypebycity.forEach((g) => {
  cities.forEach(({ slug }) => {
    routers.push({
      staticPath: [
        {
          params: {
            route: 'france', query: [slug, g.en], city: slug, slug: g.en,
          },
          locale: 'en',
        },
        {
          params: {
            route: 'france', query: [slug, g.fr], city: slug, slug: g.fr,
          },
          locale: 'fr',
        },
        {
          params: {
            route: 'france', query: [slug, g.es], city: slug, slug: g.es,
          },
          locale: 'es',
        },
      ],
      animateHeader: false,
      componentPage: 'guide-city',
      name: `guides-france-${slug}-type`,
      pattern: '/:route/:city/:slug',
    });
  });
});

// GUIDE CLASSIC
const guides = [
  {
    fr: 'location-salle-reunion',
    en: 'meeting-room-rental',
    es: 'alquiler-salas-reuniones',
  },
  {
    fr: 'evenement-entreprise',
    en: 'rent-event-space',
    es: 'rent-event-space',
  },
  {
    fr: 'production-lieu-de-tournage',
    en: 'production-rental',
    es: 'produccion-alquiler',
  },
  {
    fr: 'location-shooting-photo',
    en: 'rental-photo-shooting',
    es: 'alquiler-fotografia',
  },
  {
    fr: 'espace-de-coworking',
    en: 'coworking-space',
    es: 'coworking-space',
  },
  {
    fr: 'location-espace-atypique',
    en: 'rental-atypical-space',
    es: 'alquiler-espacio-atipico',
  },
  {
    fr: 'location-rooftop',
    en: 'rental-rooftop',
    es: 'alquiler-rooftop',
  },
  {
    fr: 'lieu-tournage',
    en: 'shooting-location',
    es: 'lugar-rodaje',
  },
  {
    fr: 'location-lieu-fashion-week',
    en: 'rent-place-fashion-week',
    es: 'alquiler-semana-moda',
  },
  {
    fr: 'lieu-pour-showroom',
    en: 'rental-showroom',
    es: 'alquiler-sala-exposiciones',
  },
  {
    fr: 'salle-reunion-louer',
    en: 'organize-meeting',
    es: 'organizar-reunion',
  },
  {
    fr: 'location-salles-formation',
    en: 'rental-training-rooms',
    es: 'alquiler-salas-formacion',
  },
  {
    fr: 'location-salles-seminaire',
    en: 'rental-seminar-rooms',
    es: 'alquiler-seminario-salas',
  },
  {
    fr: 'location-salles-conferences',
    en: 'rental-conference-rooms',
    es: 'alquiler-salas-conferencias',
  },
  {
    fr: 'rentabilier-mon-bien',
    en: 'profitability',
    es: 'rentabilidad',
  },
  {
    fr: 'location-espace-tour-de-france',
    en: 'rental-space-tour-de-france',
    es: 'lugar-espacio-tour-de-france',
  },
];

guides.forEach((g) => {
  routers.push({
    staticPath: [
      { params: { route: 'guides', query: [g.en], slug: g.en }, locale: 'en' },
      { params: { route: 'guides', query: [g.fr], slug: g.fr }, locale: 'fr' },
      { params: { route: 'guides', query: [g.es], slug: g.es }, locale: 'es' },
    ],
    animateHeader: true,
    componentPage: 'guide-classic',
    name: 'guides',
    pattern: '/:route/:slug',
  });
});

routers.push( // ======================= homepage cate===============
  {
    staticPath: [
      { params: { route: 'meeting', query: [] }, locale: 'en' },
      { params: { route: 'reunion', query: [] }, locale: 'fr' },
      { params: { route: 'reunion', query: [] }, locale: 'es' },
    ],
    animateHeader: true,
    componentPage: 'home',
    name: 'homemeeting',
    pattern: '/:category',
  },
  {
    staticPath: [
      { params: { route: 'event', query: [] }, locale: 'en' },
      { params: { route: 'evenement', query: [] }, locale: 'fr' },
      { params: { route: 'evento', query: [] }, locale: 'es' },
    ],
    animateHeader: true,
    componentPage: 'home',
    name: 'homeevent',
    pattern: '/:category',
  },
  {
    staticPath: [
      { params: { route: 'production', query: [] }, locale: 'en' },
      { params: { route: 'production', query: [] }, locale: 'fr' },
      { params: { route: 'produccion', query: [] }, locale: 'es' },
    ],
    animateHeader: true,
    componentPage: 'home',
    name: 'homeproduction',
    pattern: '/:category',
  },
  // ======================= homepage cate===============
  {
    staticPath: [
      { params: { route: 'member', query: [] }, locale: 'en' },
      { params: { route: 'membre', query: [] }, locale: 'fr' },
      { params: { route: 'miembro', query: [] }, locale: 'es' },
    ],
    name: 'userprofilpublic',
    pattern: '/:route/:userRef',
    ignore: true,
  },
);

export {
  routers,
  cities,
};
